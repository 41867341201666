@font-face {
  font-family: "AktivGrotesk";
  src: local("AktivGrotesk"),
    url("assets/fonts/AktivGrotesk-Regular.otf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  font-family: "AktivGrotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "AktivGrotesk";
}

a {
  color: inherit;
  text-decoration: none;
}

.text-truncate {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

::-webkit-scrollbar {
  background-color: #1890ff1a;
  width: 4px;
  height: 6px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: #1890ff;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.pharmacyCategories::-webkit-scrollbar {
  height: 4px;
}

.swiper {
  cursor: pointer;
}

.swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  background-color: #3c86ad !important;
}

.swiper-pagination-bullet:hover {
  background-color: #3c86ad !important;
}

.swiper-pagination-bullet-active {
  background-color: #3c86ad3d !important;
  border: 1px solid #000;
}

.swiper.swiper-free-mode {
  margin-top: 20px;
  height: 120px;
}

.navbar {
  transition: background-color 0.2s ease-out;
}

.navbar.active {
  transition: background-color 0.2s ease-in;
  background-color: #3c86ad;
}

@media (max-width: 768px) {
  .navbar.active {
    background-color: #fff;
  }
}

.navbar.active li:hover {
  color: #fff !important;
}

.mySwiper .swiper-pagination,
.swiper-scrollbar,
.swiper-scrollbar-drag,
.swiper-pagination-bullets {
  display: none !important;
}

.swiper-button-prev {
  left: -5px !important;
}

.swiper-button-next {
  right: -5px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px !important;
}

/* .swiper-button-prev:after {
  color: #3c86ad;
}

.swiper-button-next:after {
  color: #3c86ad;
} */

/* .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  width: 50% !important;
} */

/* .navbar.active button {
  color: #fff !important;
  border: 1px solid #fff !important
}

.navbar.active button:hover {
  color: #fff !important;
  background-color: #008080 !important;
  border: none !important;
} */

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
